<template>
  <div>
    <div class="flex flex-col mb-8 w-full sm:w-9/12">
      <div class="duration-300">
        <datatable
          :url="transactions.url"
          :ajax="true"
          :ajaxPagination="true"
          :columns="transactions.columns"
          :fillable="false"
          :loading="transactions.loading"
          :limit="5"
          :showHeadings="false"
          table-style="flush"
          ref="table"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      transactions: this.$options.resource([], {
        url: `${this.$baseurl}/personal/users/wallet/redeemed-transaction`,
        columns: [
          {
            name: "description",
            th: "Description",
            className: "w-full whitespace-normal",
            render: (redemption) => `
                <div class="text-gray-700 font-medium text-sm">Reference: ${
                  redemption?.reference
                }</div>
                <div class="text-gray-500 text-xs">${this.$moment(
                  redemption?.transaction_date
                ).format("dddd, MMMM Do YYYY")}</div>
              `,
          },
          {
            align: "right",
            name: "status",
            th: "Status",
            render: (redemption) => {
              return `
                  <div class="text-gray-700 font-medium text-sm">${this.$options.filters.formatAmount(
                    redemption?.amount
                  )}</div>
                  <div class="text-gray-500 text-xs flex items-center justify-end">
                    <span class="w-5px h-5px rounded-50 bg-green-500 inline-block mr-2"></span>
                    <span>Redeemed</span>
                  </div>
                `;
            },
          },
        ],
      }),
    };
  },
  methods: {
    async getWalletTransactions() {
      this.transactions.loading = true;
      await this.sendRequest("personal.wallet.redemption", {
        success: (response) => {
          this.transactions.data = response.data.data?.filter(
            (transaction) => transaction.status === "redeemed"
          );
        },
        error: (error) => console.log(error),
      });
      this.transactions.loading = false;
    },
  },
};
</script>